import React, { Fragment } from 'react';
import { graphql } from 'gatsby';
import { Banner, Headline, Quote, Text } from '@components/news-slices';
import { formatDate } from '@src/helpers';
import {
    MainWrapper,
    SectionWrapper,
    InfoBlock,
    headlineColors,
} from './article.styled';

const NewsArticle = ({ data, pageContext }) => {
    if (!data) {
        return null;
    }

    const {
        prismic: {
            news: {
                top_banner_image,
                top_banner_imageSharp,
                mobile_banner_image,
                mobile_banner_imageSharp,
                mobile_banner_alignment,
                title,
                headline_colour,
                intro_headline,
                date,
                author,
                body,
            },
        },
    } = data;
    const headlineColor = headlineColors[headline_colour || 'pinegreen'];
    return (
        <Fragment>
            <Banner
                image={top_banner_image}
                imageSharp={top_banner_imageSharp}
                mobileImage={mobile_banner_image}
                mobileImageSharp={mobile_banner_imageSharp}
                mobileAlignment={mobile_banner_alignment}
                title={title && title.length ? title[0].text : ''}
                text_color={headlineColor}
                isArticle
                isH1
            />
            <MainWrapper>
                <SectionWrapper>
                    <Headline
                        headline={intro_headline}
                        text_color={headlineColor}
                        noBottomMargin
                    />
                    {date && author ? (
                        <InfoBlock text_color={headlineColor}>
                            <div>DATE: {formatDate(date)}</div>
                            <div>WRITTEN BY: {author}</div>
                        </InfoBlock>
                    ) : null}
                </SectionWrapper>
                {body
                    ? body.map(({ type, primary }, i) => {
                          switch (type) {
                              case 'text':
                                  return (
                                      <SectionWrapper key={`text_${i}`}>
                                          <Text text={primary.text} />
                                      </SectionWrapper>
                                  );
                              case 'colored_headline':
                                  return (
                                      <SectionWrapper key={`hl_${i}`}>
                                          <Headline
                                              text_color={headlineColor}
                                              headline={primary.headline}
                                          />
                                      </SectionWrapper>
                                  );
                              case 'banner':
                                  return (
                                      <Banner
                                          key={`bn_${i}`}
                                          image={primary.background_image}
                                          imageSharp={
                                              primary.background_banner_imageSharp
                                          }
                                          mobileImage={
                                              primary.mobile_background_image
                                          }
                                          mobileImageSharp={
                                              primary.mobile_background_imageSharp
                                          }
                                          mobileAlignment={
                                              primary.mobile_background_alignment
                                          }
                                          title={
                                              primary.banner_title &&
                                              primary.banner_title.length
                                                  ? primary.banner_title[0].text
                                                  : ''
                                          }
                                          isArticle
                                          text_color={headlineColor}
                                      />
                                  );
                              case 'quote':
                                  return (
                                      <SectionWrapper key={`qt_${i}`}>
                                          <Quote
                                              text_color={headlineColor}
                                              quote={primary.quote_content}
                                              quote_author={
                                                  primary.quote_author
                                              }
                                          />
                                      </SectionWrapper>
                                  );
                              default:
                                  return null;
                          }
                      })
                    : null}
            </MainWrapper>
        </Fragment>
    );
};

export const query = graphql`
    query GetNewsArticle($uid: String!) {
        prismic {
            news(lang: "en-au", uid: $uid) {
                author
                _meta {
                    uid
                }
                headline_colour
                intro_headline
                title
                date
                body {
                    ... on PRISMIC_NewsBodyBanner {
                        type
                        label
                        primary {
                            banner_title
                            background_image
                            background_imageSharp {
                                childImageSharp {
                                    fluid {
                                        srcSet
                                        src
                                    }
                                }
                            }
                            mobile_background_image
                            mobile_background_imageSharp {
                                childImageSharp {
                                    fluid {
                                        srcSet
                                        src
                                    }
                                }
                            }
                            mobile_background_alignment
                        }
                    }
                    ... on PRISMIC_NewsBodyText {
                        primary {
                            text
                        }
                        type
                    }
                    ... on PRISMIC_NewsBodyColored_headline {
                        primary {
                            headline
                        }
                        type
                    }
                    ... on PRISMIC_NewsBodyQuote {
                        primary {
                            quote_author
                            quote_content
                        }
                        type
                    }
                    ... on PRISMIC_NewsBodyExtra_spacing {
                        type
                        primary {
                            num_em
                        }
                    }
                }
                top_banner_image
                top_banner_imageSharp {
                    childImageSharp {
                        fluid {
                            srcSet
                            src
                        }
                    }
                }
                mobile_banner_image
                mobile_banner_imageSharp {
                    childImageSharp {
                        fluid {
                            srcSet
                            src
                        }
                    }
                }
                mobile_banner_alignment
            }
        }
    }
`;

export default NewsArticle;
