import styled from 'styled-components';
import { generateBreakpoint, generateCol } from '@layouts/grid';
import colors from '@shared/colors';
import { generateVwPercentageHeight } from '@src/helpers';

export const StyledOuter = styled.div`
    position: relative;
    ${generateCol(24)}
    width: 100%;
    overflow: hidden;

    ${props =>
        props.isH1 && props.isArticle
            ? `margin-bottom: 32px;
        ${generateBreakpoint('md')('margin-bottom: 48px;')}
        `
            : ''}

    ${props =>
        !props.isH1 && props.isArticle
            ? `margin-bottom: 48px;
        ${generateBreakpoint('lg')('margin-bottom: 64px;')}`
            : ''}

    ${generateVwPercentageHeight(337, 550, 320)}

    ${props =>
        props.desktop
            ? `${generateBreakpoint('lg')(generateVwPercentageHeight(774))}`
            : ''}
`;

const LEFT_ALIGNMENT = `left: 0%;
    top: 50%;
    transform: translate(0, -50%);`;

const CENTER_ALIGNMENT = `left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);`;

const RIGHT_ALIGNMENT = `right: 0%;
    top: 50%;
    transform: translate(0%, -50%);`;

const generateAlignment = alignmentString => {
    switch (alignmentString) {
        case 'center':
            return CENTER_ALIGNMENT;
        case 'left':
            return LEFT_ALIGNMENT;
        case 'right':
            return RIGHT_ALIGNMENT;
        default:
            return CENTER_ALIGNMENT;
    }
};

export const StyledInner = styled.div`
    position: absolute;

    width: 1024px;
    height: 550px;
    ${props => generateAlignment(props.mobileAlignment)}

    ${props =>
        props.mobile ? `${generateBreakpoint('lg')('display: none;')}` : ''}

    ${props =>
        props.desktop
            ? `display: none;
        ${generateBreakpoint('lg')(`
        display: block;
        width: 2048px;
        height: 774px;`)}`
            : ''}

    img {
        position: relative;
        z-index: 3;
        height: 100%;
        width: 100%;
    }
`;

export const TextWrapper = styled.div`
    position: relative;
    z-index: 4;
    display: flex;
    height: 100%;
    width: 100vw;
    max-width: 100%;
    text-align: center;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;

    word-break: break-word;

    color: ${props => (props.text_color ? props.text_color : colors.pineGreen)};
`;

export const StyledH1 = styled.h1`
    font-size: 60px;
    font-weight: bold;
    line-height: 0.83;
    letter-spacing: -3px;
    max-width: 75%;

    ${generateBreakpoint('md')('font-size: 80px')}

    ${generateBreakpoint('lg')(
        `font-size: 100px;
        line-height: 0.87;
        letter-spacing -5px;`,
    )}

    ${generateBreakpoint('lg_xl')('font-size: 120px')}

    ${generateBreakpoint('xl')(
        `font-size: 160px;
        line-height: 0.94;
        letter-spacing: -10px;
        max-width: 90%;`,
    )}

    ${generateBreakpoint('xxl')(`max-width: 80%;`)}
`;

export const StyledH2 = styled.h2`
    font-size: 45px;
    font-weight: bold;
    line-height: 0.83;
    letter-spacing: -3px;
    max-width: 75%;

    ${generateBreakpoint('md')('font-size: 60px')}

    ${generateBreakpoint('lg')(
        `font-size: 75px;
        line-height: 0.87;
        letter-spacing -5px;
        max-width: 60%;`,
    )}

    ${generateBreakpoint('lg_xl')('font-size: 90px')}

    ${generateBreakpoint('xl')(
        `font-size: 120px;
        line-height: 0.94;
        letter-spacing: -10px;`,
    )}
`;
