import React from 'react';
import styled from 'styled-components';
import { RichText } from '@components/shared';
import colors from '@shared/colors';

const StyledText = styled.div`
    font-family: 'SuisseIntl';
    font-size: 19px;
    line-height: 1.47;
    letter-spacing: 0.29px;
    text-align: left;
    color: #000000;

    a {
        &,
        &:visited,
        &:active {
            color: ${colors.violetBlue};
        }
    }
`;

export const Text = ({ text }) =>
    text ? (
        <StyledText>
            <RichText render={text} />
        </StyledText>
    ) : null;

export default Text;
