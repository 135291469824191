import React from 'react';
import { RichText } from '@components/shared';
import { StyledQuote } from './quote.styled';

export const Quote = ({ quote_author, quote, text_color, noBottomMargin }) => {
    return quote && quote.length ? (
        <StyledQuote text_color={text_color} noBottomMargin={noBottomMargin}>
            <RichText render={quote} />
            {quote_author ? <small>{quote_author}</small> : null}
        </StyledQuote>
    ) : null;
};

export default Quote;
