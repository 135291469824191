import styled from 'styled-components';
import { generateGrid, generateCol } from '@layouts/grid';
import colors from '@shared/colors';
import { generateBreakpoint } from '../../layouts/grid';

export const MainWrapper = styled.div`
    ${generateGrid(24, 8)}
    padding-bottom: 48px;
    min-height: 100vh;
    background-color: transparent;
`;

export const SectionWrapper = styled.section`
    ${generateCol(24)}
    padding: 0 20px;
    padding-bottom: 48px;

    &:first-child {
        padding-bottom: 20px;
    }
    ${generateBreakpoint('md')(
        `padding-left: 0;
        padding-right: 0;
        padding-bottom: 48px;
        ${generateCol(20, 3)}`,
    )}
    ${generateBreakpoint('lg')(
        `${generateCol(16, 5)}
        padding-bottom: 64px;`,
    )}
    ${generateBreakpoint('xl')(generateCol(12, 7))}

    ${generateBreakpoint('xxl')(generateCol(10, 8))}

`;

export const InfoBlock = styled.div`
    font-size: 14px;
    line-height: 1.71;

    text-align: center;
    padding-top: 38px;
    padding-bottom: 15px;

    color: ${props => props.text_color};
`;

export const headlineColors = {
    pinegreen: colors.pineGreen,
    violetblue: colors.violetBlue,
    mauve: colors.mauve,
};
