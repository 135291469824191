import styled from 'styled-components';
import { generateBreakpoint } from '@layouts/grid';

export const StyledQuote = styled.div`
    line-height: 1.1;
    letter-spacing: -0.6px;
    color: ${props => props.text_color};
    text-align: center;
    font-weight: 400;
    margin: 0 auto;

    ${props => (props.noBottomMargin ? 'padding-bottom: 0;' : '')}

    ${generateBreakpoint('sm')(`width: 80%;`)}

    ${generateBreakpoint('md')(`width: 66%;`)}

    p {
        font-size: 28px;
        padding-bottom: 12px;
        quotes: '”' '“';
        ::before {
            box-sizing: border-box;
            content: open-quote;
            font-style: italic;
        }
        ::after {
            box-sizing: border-box;
            content: close-quote;
            font-style: italic;
        }
    }

    small {
        font-family: 'ApercuMonoPro', Helvetica, sans-serif;
    }
`;
