import React from 'react';
import Image from '../image';
import {
    StyledH1,
    StyledH2,
    StyledInner,
    StyledOuter,
    TextWrapper,
} from './banner.styled';

export const MobileBanner = ({
    mobileImage = {},
    mobileImageSharp,
    mobileAlignment,
}) => {
    return (
        <StyledInner mobileAlignment={mobileAlignment}>
            <Image image={mobileImage} imageSharp={mobileImageSharp} />
        </StyledInner>
    );
};

export const Banner = ({
    image = {},
    imageSharp,
    mobileImage = {},
    mobileImageSharp,
    mobileAlignment,
    text_color,
    title,
    isH1 = false,
    isArticle = false,
}) => {
    return (
        <StyledOuter isArticle={isArticle} isH1={isH1}>
            <TextWrapper text_color={text_color}>
                {isH1 ? (
                    <StyledH1>{title}</StyledH1>
                ) : (
                    <StyledH2>{title}</StyledH2>
                )}
            </TextWrapper>
            <StyledInner mobile mobileAlignment={mobileAlignment}>
                <Image image={mobileImage} imageSharp={mobileImageSharp} />
            </StyledInner>
            <StyledInner desktop>
                <Image image={image} imageSharp={imageSharp} />
            </StyledInner>
        </StyledOuter>
    );
};

export default Banner;
