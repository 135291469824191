import React from 'react';
import styled from 'styled-components';
import { RichText } from '@components/shared';
import { generateBreakpoint } from '../../layouts/grid';

const StyledHeadline = styled.div`
    font-family: 'SuisseIntl';
    line-height: 1.1;
    letter-spacing: -0.6px;
    color: ${props => props.text_color};
    text-align: center;

    ${props => (props.noBottomMargin ? 'padding-bottom: 0;' : '')}

    h2 {
        font-size: 28px;
    }

    ${generateBreakpoint('md')(
        `h2 {
            font-size: 40px;
        }`,
    )}
`;

export const Headline = ({ headline, text_color, noBottomMargin }) => {
    return headline && headline.length ? (
        <StyledHeadline text_color={text_color} noBottomMargin={noBottomMargin}>
            <RichText render={headline} />
        </StyledHeadline>
    ) : null;
};

export default Headline;
